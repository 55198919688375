import {
  RoleEdit_default
} from "./chunk-7CGZOOWW.js";
import {
  RoleViewModelContextProvider
} from "./chunk-6TGZT635.js";

// src/pages/Roles/edit.tsx
import React, { Component } from "react";
import { observer } from "mobx-react";
var EditRoleProvider = observer(
  class EditRoleProvider2 extends Component {
    render() {
      return /* @__PURE__ */ React.createElement(RoleViewModelContextProvider, null, /* @__PURE__ */ React.createElement(RoleEdit_default, null));
    }
  }
);

export {
  EditRoleProvider
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
